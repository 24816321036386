<template>
  <div class="bg bg5">
    <div class="class--con">
      <class-but @forward="forward"/>
<!--      <div style="text-align: center">-->
<!--        <img :src="menu1" alt="">-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import menu1 from "@/assets/image/conference/pic_guih.png"

export default {
  name: "fourteenPage",
  components: {ClassBut},
  data() {
    return {
      menu1: menu1
    }
  },
  methods: {
    forward() {
      this.$router.push({
        name: 'special2023',
        query: this.$route.query,
      })
    },
  }
}
</script>

<style lang="less" scoped>
@import "reportClass.less";
</style>
